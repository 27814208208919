import styled from 'styled-components';

const Custom404Style = styled.section`
	--containerPaddingT: var(--sp10x);
	--containerPaddingB: var(--sp20x);
	--marginT: var(--sp5x);
	--marginB: var(--sp3x);
	--buttonMarginT: var(--sp5x);
	--fontSize: 300px;

	.error-container {
		padding: var(--containerPaddingT) 0 var(--containerPaddingB);
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.main-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.error-text {
		font-size: var(--fontSize);
		font-family: var(--montserrat_arm);
		font-weight: 700;
	}

	.title {
		margin: var(--marginT) 0 var(--marginB);
	}

	.btn {
		margin-top: var(--buttonMarginT);
	}

	//! 1920
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--containerPaddingT: var(--sp8x);
		--containerPaddingB: var(--sp15x);
		--fontSize: 288px;
	}

	//! 1536
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--containerPaddingT: var(--sp9x);
		--containerPaddingB: var(--sp16x);
		--fontSize: 288px;
	}

	//! 1366
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--marginT: var(--sp3x);
		--containerPaddingT: var(--sp1x);
		--containerPaddingB: var(--sp13x);
		--fontSize: 230px;
	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--marginT: var(--sp3x);
		--containerPaddingT: var(--sp3x);
		--containerPaddingB: var(--sp12x);
		--fontSize: 230px;
	}

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--marginT: var(--sp3x);
		--containerPaddingT: var(--sp1x);
		--containerPaddingB: var(--sp10x);
		--fontSize: 180px;

		.description {
			padding: 0 var(--sp1x);
		}
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--marginT: var(--sp3x);
		--containerPaddingT: var(--sp1x);
		--containerPaddingB: var(--sp8x);
		--fontSize: 120px;

		.description {
			padding: 0 var(--sp1x);
		}
	}
`;

export default Custom404Style;
