'use client';

import { useEffect } from 'react';
import { usePathname, useRouter } from 'next/navigation';

//* HOC's
import { withLanguageContext } from '@/context';

//* Helper's
import { config } from '@/helpers';

//* Components
import Text from '@/components/Text';
import Button from '@/components/Button';
import Container from '@/components/Container';

//* Style
import Custom404Style from './Custom404Style';

const NotFound = ({ selectedLang, translate }) => {
	//! Next Navigation
	const router = useRouter();
	const pathname = usePathname();

	//! Check
	useEffect(() => {
		if (!(pathname.replace(selectedLang + '/', '') == '/not-found/')) {
			router.push(`/${selectedLang}/not-found/`) || router.push(`/not-found/`);
		}
	}, [router, pathname, selectedLang]);

	return (
		<Custom404Style>
			<Container
				row
				className={'error-container'}>
				<div className={`col-12 main-wrapper text-center`}>
					<Text
						className={`blue-900 error-text text-center`}
						text={'404'}
					/>

					<Text
						tag={`h1`}
						className={`h4 font-montserrat-arm-medium blue-900 title uppercase text-center`}
						text={'404title'}
					/>

					<Text
						className={'p3 font-montserrat-arm-regular blue-900 description text-center'}
						text={'404description'}
					/>

					<Button
						className={`btn`}
						text={translate('goToHomepage')}
						url={config.routes.home.path}
					/>
				</div>
			</Container>
		</Custom404Style>
	);
};

export default withLanguageContext(NotFound, ['selectedLang', 'translate']);
